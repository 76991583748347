
import { Fancybox } from "@fancyapps/ui";

import { Carousel } from "@fancyapps/ui";
import "@fancyapps/ui/dist/carousel/carousel.css";

import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      isInit: false,
      sl: null,
      sl__mini: null
    };
  },
  props: {
    isForm: {
      type: Boolean,
      default: false
    },
    url: String,
    available: {
      type: Boolean,
      default: false
    },
    isNewCar:{
      type: Boolean,
      default: false
    },
    offer: {
      type: Object | null,
      default: null
    },
    buttons: {
      type: Boolean,
      default: true
    },
    vin: {
      type: Boolean,
      default: true
    },
    activeFancybox: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: false
    },
    callback: {
      type: Boolean,
      default: false
    },
    noThumb: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      likesArray: "favorite/favorite/likesArray",
      currentCar: "modal/modal-choose/currentCar",
      settings: "settings/settings/settings",
      storeOffer: "catalog/catalog-cars/offer",
			isNight: "settings/settings/isNight"
    }),
    currentOffer() {
      return this.offer ? this.offer : storeOffer;
    },
    device() {
      return this.$device.isMobile ? this.$device.isMobile : false;
    },
    currentOffer() {
      return this.offer;
    },
  },
  methods: {
    ...mapActions({
      liked: "favorite/favorite/liked",
      openModal: "modal/modal-main/openModal",
      closeModal: "modal/modal-main/closeModal"
    }),
    ...mapMutations({
      setCarPageLoaded: "catalog/catalog-cars/SET_CAR_PAGE_LOADED",
      setOffer: "catalog/catalog-cars/SET_OFFER"
    }),
    async callbackFunc() {
      let payload = {
        modal_component: "modal-callback-main"
      };
      await this.openModal(payload);
    },
    async like() {
      await this.liked(this.offer.external_id);
    },
    async autoteka(carInfo) {
      let payload = {
        modal_data: carInfo,
        modal_component: "modal-autoteka",
        modal_title: "Отчет от " + carInfo.createdAt,
        modal_sub_title: carInfo.name
      };
      this.openModal(payload);
    },
    initSliders() {
      if (!this.$device.isMobile) {
        this.sl = new Carousel(
          document.querySelector(`#mainCarousel${this.offer.id}`),
          {
            Dots: {
              dynamicFrom: 5
            },
            on: {
              init: () => {
                this.$emit("sliderInit");
              }
            },
            // dragFree:true,
            Navigation: {
              container: "f-carousel__nav",
              // button: "f-button",
              nextTpl: `<div data-carousel-next="true" class="swiper-button__arrow swiper-button__arrow--right swiper-button__arrow--mini" >
						<div class="arrow"></div>
					</ div>`,
              prevTpl: `<div data-carousel-prev="true" class="swiper-button__arrow swiper-button__arrow--left swiper-button__arrow--mini" >
						<div class="arrow"></div>
					</ div>`
            }
          }
        );

        // Thumbnails
        if (!this.noThumb) {
          this.sl__mini = new Carousel(
            document.querySelector(`#thumbCarousel${this.offer.id}`),
            {
              Sync: {
                target: this.sl
              },
              Dots: false,
              Navigation: false,
              center: true,
              slidesPerPage: 1,
              infinite: true
            }
          );
        }
      } else {
        this.$emit("sliderInit");
      }

      this.setCarPageLoaded(true);
    }
  },

  mounted() {
    if (this.activeFancybox)
      Fancybox.bind(
        `[data-fancybox="gallery-${this.offer.id}${
          this.modal ? `-modal` : ""
        }"]`,
        {
          groupAll: true,
          compact: this.$device.isMobile ? true : false
        }
      );
    // Initialise Carousel
    this.initSliders();
  },
  watch: {
    offer() {
      if (this.activeFancybox)
        Fancybox.bind(
          `[data-fancybox="gallery-${this.offer.id}${
            this.modal ? `-modal` : ""
          }"]`,
          {
            groupAll: true,
            compact: this.$device.isMobile ? true : false
          }
        );
    }
  }
  // unmounted() {
  // 	this.setOffer(null)
  // }
};
